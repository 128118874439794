import { Stack, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

interface MFAuthenticationInputProps {
	value?: string,
	onChange?: ( value: string ) => void
}

export default function MFAuthenticationInput( {
	value = '',
	 
	onChange = () => {},
}: MFAuthenticationInputProps ) {
	const [ values, setValues ] = useState<string[]>( Array( 6 ).fill( '' ) );
	const inputRefs = useRef<( HTMLInputElement | null )[]>( [] );
	
	useEffect( () => {
		if ( value ) {
			setValues( Array.from( value.padEnd( 6, '' ) ) );
		}
	}, [ value ] );
	
	useEffect( () => {
		onChange( values.join( '' ) );
	}, [ values, onChange ] );
	
	const textFieldStyles = {
		'width'                : 50,
		'height'               : 60,
		'mr'                   : 1,
		'textAlign'            : 'center',
		'borderRadius'         : '4px',
		'& .MuiInputBase-input': {
			textAlign: 'center',
			fontSize : 22,
			height   : '100%',
		},
	};
	
	const handlePaste = ( event: React.ClipboardEvent<HTMLDivElement> ) => {
		event.preventDefault();
		const pasteData = event.clipboardData.getData( 'text/plain' );
		const pasteDigits = pasteData.replace( /\D/g, '' ).slice( 0, 6 ).split( '' );
		
		if ( pasteDigits.length > 0 ) {
			const newValues = [ ...pasteDigits ];
			setValues( newValues );
			
			const focusIndex = pasteDigits.length >= 6 ? 5 : pasteDigits.length;
			inputRefs.current[ focusIndex ]?.focus();
		}
	};
	
	const handleKeyDown = ( event: React.KeyboardEvent, index: number ) => {
		const isPasteEvent = ( event.key === 'v' || event.key === 'V' ) && ( event.ctrlKey || event.metaKey );
		
		if ( !/[0-9]/.test( event.key ) && event.key !== 'Backspace' && event.key !== 'Tab' && !isPasteEvent ) {
			event.preventDefault();
		} else if ( event.key === 'Backspace' && index > 0 ) {
			event.preventDefault();
			const newValues = [ ...values ];
			if ( values[ index ] === '' ) {
				newValues[ index - 1 ] = '';
				inputRefs.current[ index - 1 ]?.focus();
			} else {
				newValues[ index ] = '';
			}
			setValues( newValues );
		}
	};
	
	const handleInputChange = ( index: number, newValue: string ) => {
		const newValues = [ ...values ];
		newValues[ index ] = newValue;
		setValues( newValues );
		
		if ( newValue.length === 1 && index < 5 ) {
			inputRefs.current[ index + 1 ]?.focus();
		}
	};
	
	return (
		<Stack direction='row'>
			{[ ...Array( 6 ) ].map( ( _, index ) => (
				<TextField
					key={index}
					inputProps={{ maxLength: 1 }}
					sx={textFieldStyles}
					value={values[ index ]}
					inputRef={( el ) => inputRefs.current[ index ] = el}
					onKeyDown={( event ) => handleKeyDown( event, index )}
					onPaste={handlePaste}
					onChange={( event ) => handleInputChange( index, event.target.value )}
				/>
			) )}
		</Stack>
	);
}


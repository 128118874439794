import useUserInfo from '@/providers/auth/useUserInfo';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useRedirectPostLogin = () => {
	const { user, staff } = useUserInfo();
	const router = useRouter();
	
	const redirectUrl = router.query.redirect as string;
	
	useEffect( () => {
		if ( !user ) return;
		if ( redirectUrl ) {
			console.debug( 'redirecting to', redirectUrl );
			router.push( redirectUrl );
		} else if ( staff ) {
			console.debug( 'redirecting to dashboard' );
			router.replace( '/dashboard' );
		} else if ( user?.clientOnly ) {
			console.debug( 'redirecting to client' );
			router.replace( '/client' );
		} else {
			console.debug( 'redirecting to welcome' );
			router.replace( '/welcome' );
		}
	}, [ user ] );
};

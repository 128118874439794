import { useEffect } from 'react';

/**
 * useEventListener
 *
 * @param eventType - The event type (e.g., 'keydown', 'click')
 * @param handler - The function to be called when the event occurs
 * @param target - The target to attach the listener to (default is window)
 */
export default function useEventListener(
	eventType: string,
	handler: ( event: Event ) => void,
	target: EventTarget = document,
): void {
	useEffect( () => {
		const listener = ( event: Event ) => handler( event );
		
		target.addEventListener( eventType, listener );
		
		return () => {
			target.removeEventListener( eventType, listener );
		};
	}, [ eventType, handler, target ] );
}

import Disclaimer from '@/components/Disclaimer';
import CustomGoogleButton from '@/components/auth/googleButton';
import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormPassword from '@/components/form/fields/password';
import FormTextField from '@/components/form/fields/textField';
import { loginSignUpErrorMessages, useMultiFactorErrorHandling } from '@/components/multiFactorAuth/helpers';
import ValidateMFAuthCode from '@/components/multiFactorAuth/validateMFAuthCode';
import PageLinkComponent from '@/components/page/linkComponent';
import StyledImage from '@/components/styledImage';
import { useGraphQL } from '@/data';
import { auth } from '@/firebase/client';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { useRedirectPostLogin } from '@/hooks/useRedirect';
import { logout } from '@/providers/auth';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useAutoSplash } from '@/providers/splash';
import { useStaticTheme } from '@/providers/staticTheme';
import { QueryCompanyReadArgs } from '@/types/schema';
import wait from '@/utils/wait';
import { gql } from '@apollo/client';
import { Box, Button, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { companyModeIdAtom } from './_app.component';
import StaticPageLayout from './static/_layout';

export const loginValidation = yup.object().shape( {
	email   : yup.string().email( 'Invalid email address' ).required( 'Email is required' ),
	password: yup.string().required( 'Enter a password' ),
} );

type LoginFormType = {
	email: string,
	password: string
};

export function LoginForm( { isCloverDevice = false } ) {
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	const { verificationCodeId, mfaResolver, phoneNbr, handleMultiFactorError } = useMultiFactorErrorHandling();
	
	const companyId = useAtomValue( companyModeIdAtom );
	const { data } = useGraphQL<QueryCompanyReadArgs>( {
		queryKey: [ 'companyId' ],
		query   : gql`
			query companyRead_2926($id: String!) {
				companyRead(id: $id) {
					name
					logo
				}
			}
		`,
		variables: { id: companyId },
	}, { enabled: Boolean( companyId ) } );
	
	const companyMode = data?.companyRead;
	
	return (
		<Container className='borderedContainer' maxWidth='xl'>
			<Grid container sx={{ pt: 7, pb: 15 }}>
				<Grid
					item
					xs={12}
					sx={{
						'position'              : 'relative',
						'display'               : 'flex',
						'justifyContent'        : 'center',
						'.MuiOutlinedInput-root': { height: 45, fontSize: '1.4rem' },
						'input:-webkit-autofill': {
							WebkitTransitionDelay: '99999s',
							WebkitTextFillColor  : '#000000 !important',
							WebkitBoxShadow      : 'unset !important',
						},
					}}>
					{companyMode?.logo && (
						<StyledImage
							sx={{ position: 'absolute', bottom: 'calc(100% + 20px)', height: 80 }}
							src={companyMode.logo}
							alt='logo'
						/>
					)}
					{verificationCodeId && mfaResolver ? (
						<Box sx={{ width: { xs: 350, sm: 450 } }}>
							<ValidateMFAuthCode
								verificationId={verificationCodeId}
								resolver={mfaResolver}
								phoneNbr={phoneNbr}
								typographyProps={{
									title: {
										variant : 'h1',
										children: 'Lets verify your identity',
									},
								}}
							/>
						</Box>
					) : (
						<Form<LoginFormType>
							initialValues={{ email: ( router.query?.email as string ) || '', password: '' }}
							validationSchema={loginValidation}
							onSubmit={async ( { email, password } ) => {
								await logout();
								try {
									await signInWithEmailAndPassword( auth, email, password );
									await wait( 10000 );
								} catch ( e ) {
									console.error( e );
									if ( e.code === 'auth/multi-factor-auth-required' ) {
										return await handleMultiFactorError( e );
									} else {
										enqueueSnackbar( loginSignUpErrorMessages[ e.code || e.message || e ] || 'Failed to authenticate.', { variant: 'warning' } );
									}
								}
							}}>
							{( formik: any ) => (
								<Container maxWidth='xs'>
									<Paper sx={{ p: 2 }}>
										<Typography variant='h2' sx={{ mb: 2, fontWeight: 500 }}>
											Login in to {companyMode?.name || 'Invoiss'}
										</Typography>
										<Stack key='Login' spacing={3} my={2}>
											<FormTextField
												fullWidth
												label='Email'
												name='email'
												placeholder='Email'
												spellCheck='false'
												autoCorrect='off'
												inputProps={{
													autoCorrect   : 'off',
													spellCheck    : 'false',
													autoCapitalize: 'none',
												}}
											/>
											<FormPassword
												label='Password'
												name='password'
												placeholder='Password'
												spellCheck='false'
												onKeyDown={( e ) => {
													if ( e.key === 'Enter' ) {
														formik.submitForm();
													}
												}}
											/>
										</Stack>
										<Stack spacing={2}>
											<AsyncLoadingButton
												variant='text'
												color='primary'
												onClick={async () => {
													await sendPasswordResetEmail( auth, formik.values.email );
													enqueueSnackbar( 'Password reset email sent', { variant: 'success' } );
												}}>
												Forgot password?
											</AsyncLoadingButton>
											<AsyncLoadingButton
												fullWidth
												variant='contained'
												color='primary'
												size='large'
												onClick={formik.submitForm}>
												Login
											</AsyncLoadingButton>
											{!isCloverDevice && (
												<Button
													fullWidth
													color='primary'
													variant='text'
													component={PageLinkComponent}
													href={router.query.redirect
														? `/signup?redirect=${router.query.redirect as string}`
														: '/signup'}>
													Dont have an account? Sign up
												</Button>
											)}
										</Stack>
									</Paper>
									{!isCloverDevice && !window.ReactNativeWebView && (
										<Stack spacing={2} mt={2}>
											<Divider>
												<Typography sx={{ fontWeight: 500, color: 'text.secondary' }}>
													Or Sign in/Sign up with
												</Typography>
											</Divider>
											<CustomGoogleButton
												onClick={async () => {
													try {
														await signInWithPopup( auth, new GoogleAuthProvider() );
													} catch ( error ) {
														if ( error.code === 'auth/multi-factor-auth-required' ) {
															return await handleMultiFactorError( error );
														}
														enqueueSnackbar( loginSignUpErrorMessages[ error.code || error ] || 'Failed to authenticate.' );
													}
												}}>
												Login with Google
											</CustomGoogleButton>
											<Disclaimer/>
										</Stack>
									)}
								</Container>
							)}
						</Form>
					)}
				</Grid>
			</Grid>
		</Container>
	);
}

export default function Login() {
	useStaticTheme( 'light' );
	const { user } = useUserInfo();
	const { isCloverDevice } = useGetDeviceInfo();
	
	useRedirectPostLogin();
	useAutoSplash( Boolean( user ), 1000 );
	
	return (
		<StaticPageLayout navbarContainerProps={{ maxWidth: 'xl' }} footerContainerProps={{ maxWidth: 'xl' }}>
			<LoginForm isCloverDevice={isCloverDevice}/>
		</StaticPageLayout>
	);
}

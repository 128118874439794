import useCountdown from '@/hooks/useCountdown';
import useEventListener from '@/hooks/useEventListener';
import { Stack, Typography, TypographyProps } from '@mui/material';
import { MultiFactorResolver } from 'firebase/auth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import AsyncLoadingButton from '../form/asyncLoading/asyncLoadingButton';
import { enrollUserInMultiFactorAuthentication, loginSignUpErrorMessages, validateCode } from './helpers';
import MFAuthenticationInput from './MFAInput';

export default function ValidateMFAuthCode( {
	resolver,
	verificationId,
	resendCode,
	typographyProps,
	enrollUser,
	phoneNbr,
}: {
	verificationId: string,
	resolver?: MultiFactorResolver,
	resendCode?: () => Promise<void>,
	enrollUser?: boolean,
	phoneNbr?: string,
	typographyProps?: {
		title?: TypographyProps,
		subtitle?: TypographyProps
	}
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const { disabled, countdown, restartCountdown } = useCountdown( 60000 );
	
	const [ code, setCode ] = useState<string>( '' );
	
	const resendCodeDefault = async () => null;
	
	const validateCodeInternal = async () => {
		try {
			const result = enrollUser
				? await enrollUserInMultiFactorAuthentication( verificationId, code )
				: await validateCode( verificationId, code, resolver! );
			if ( result ) {
				// location.reload(); //why reload?
			}
		} catch ( e ) {
			const errorMessage = loginSignUpErrorMessages[ e.code || e.message || e ] || 'Failed to validate code';
			enqueueSnackbar( errorMessage, { variant: 'error' } );
		}
	};
	
	const resendCodeInternal = async () => {
		try {
			if ( resendCode ) {
				await resendCode?.();
			} else {
				await resendCodeDefault();
			}
		} catch ( e ) {
			const errorMessage = loginSignUpErrorMessages[ e.code || e.message || e ] || 'Failed to send code';
			enqueueSnackbar( errorMessage, { variant: 'error' } );
		}
	};
	
	useEventListener( 'keydown', ( event: KeyboardEvent ) => {
		if ( ( event.code === 'Enter' || event.code === 'NumpadEnter' ) && !Boolean( code?.length < 6 ) ) {
			event.preventDefault();
			
			const button = document.getElementById( 'validateButton' );
			button?.click();
		}
	} );
	
	return (
		<Stack spacing={2}>
			<Stack sx={{ width: '100%' }} spacing={2}>
				<Typography
					variant='h1'
					{...typographyProps?.title}>
					Enter the code we sent you
				</Typography>
				<Typography
					sx={{ color: 'text.secondary', fontSize: '16px !important' }}
					{...typographyProps?.subtitle}>
					We sent a code to your phone number {phoneNbr ? `(${phoneNbr})` : ''}.
				</Typography>
			</Stack>
			<MFAuthenticationInput
				value={code}
				onChange={( newCode ) => setCode( newCode )}
			/>
			<Stack spacing={2} sx={{ width: '100%' }}>
				<AsyncLoadingButton
					fullWidth
					id='validateButton'
					disabled={Boolean( code?.length < 6 )}
					sx={{ mt: 2 }}
					variant='contained'
					color='primary'
					size='large'
					onClick={validateCodeInternal}>
					Validate
				</AsyncLoadingButton>
				{resendCode && (
					<AsyncLoadingButton
						fullWidth
						color='primary'
						variant='text'
						size='large'
						disabled={disabled}
						onClick={async () => {
							if ( countdown > 0 ) return;
							await resendCodeInternal();
							restartCountdown();
						}}>
						{countdown > 0 ? `Resend code (${countdown})` : 'Resend code'}
					</AsyncLoadingButton>
				)}
			</Stack>
		</Stack>
	);
}
